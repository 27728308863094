<template>
    <div class="wh-100 mobile-portal ">
        <section class="header">
            <div class="search-bar-wrap">
                <nut-textinput class="search-bar"
                               :placeholder="$t('TITLE.SEARCH')"
                               :clearBtnPersonnal="true"
                               v-model="search_filter">
                    <template slot="clearBtn">
                        <nut-icon type="circle-cross" color="#FFFFFF"></nut-icon>
                    </template>
                </nut-textinput>
                <div class="language" @click="setLocale">
                    <img src="~@/assets/images/globe.svg" width="22" height="22" alt="globe"/>
                </div>
            </div>
            <div class="header-wrap">
                <section class="header-menu">
                    <div v-for="x in menu" :key="x.key" class="header-menu-item" :class="{active:pageKey===x.key}"
                         @click=" pageKey!==x.key && $router.push(x.url)">
                        {{ english ? x.english : x.name }}
                    </div>
                    <div class="menu-indicator" :style="{left:indicate_left,color:'red'}"></div>
                </section>
            </div>
        </section>
        <section class="content-wrap">
            <keep-alive>
                <router-view :key="pageKey"></router-view>
            </keep-alive>
        </section>
    </div>
</template>
<script>
import CommonMixin from "@/common/mixins/common-mixin";
import "@/plugins/nutui"

export default {
    name: "public",
    mixins: [CommonMixin],
    data() {
        return {
            search_filter: "",
            menu: [
                {name: '推荐', key: '主页', english: 'Portal', url: 'portal', index: 0},
                {name: '直播', key: '直播', english: 'Live', url: 'live', index: 1},
                {name: '回放', key: '回播', english: 'Replay', url: 'playback', index: 2},
                {name: '档案', key: '档案', english: 'Profile', url: 'profile', index: 3}
            ],
        }
    },
    watch: {
        search_filter(newVal) {
            this.setFilter(newVal);
        },
        filter(value) {
            if (value !== this.search_filter) {
                this.search_filter = value;
            }
        }
    },
    components: {},
    computed: {
        filter() {
            return this.process.filter;
        },
        pageKey() {
            if (this.$route.meta) {
                if (this.$route.meta.model) return this.$route.meta.model;
                else return this.$route.name;
            }
            return '';
        },
        indicate_left() {
            const key = this.pageKey;
            for (let i = 0; i < this.menu.length; i++) {
                if (this.menu[i].key === key) {
                    if (i === 0) return "0";
                    return "calc(  ((100% - 220px) / 3 + 55px) * " + i + ")"
                }
            }
            return "0"
        },
    },
    created() {
        this.setWindowTitle();
    }

}
</script>

<style lang="scss">
@import "~@/views/scss/mobile-public.scss";
</style>
